import React, { useEffect, useState} from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';



const Mahal = () => {

    const [open, setOpen] = useState(true); // State to manage dialog open/close

    //POP UP    
    const handleClose = () => {
        setOpen(false);
    };
    // END POP UP


    useEffect(() => {
        document.title = 'Mahal | Tsahal Connection';
      }, []);
      
    return (
        <div>
            <Navbar />
            <Fade>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Services</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Mahal</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', marginBottom: '1rem', fontWeight: '500', color: '#2f3036'}}>
                Mahal est un programme du ministère de la Défense existant depuis 1948. Il permet aux étrangers et aux israéliens n’ayant pas vécu en Israël de s’engager dans Tsahal en tant que volontaire pour une durée de 18 mois. Il existe trois sessions par an (Avril, Août, Décembre) et la présence du participant est requise deux mois avant le début de chaque session pour divers entretiens.<br />
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', marginBottom: '4rem', fontWeight: '500', color: '#2f3036'}}>
                    <span style={{textDecoration: 'underline', fontStyle: 'italic'}}>Touriste</span> - <span style={{fontStyle: 'italic'}}>n’est pas citoyen israélien et aucun de ses parents n’a jamais été citoyen israélien.</span><br />
                    <span style={{textDecoration: 'underline', fontStyle: 'italic'}}>Ben Meager</span> - <span style={{fontStyle: 'italic'}}>enfants de parents israéliens qui sont nés à l’étranger, ou en Israël et qui ont quitté le pays avant ses seize ans. C’est-à-dire qu’un de ses parents à où avait la citoyenneté israélienne et donc il est éligible à recevoir un passeport israélien.</span><br />
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700' }}>
                   Les conditions d'admission
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                1. Touriste ou « Ben Meager » (temps autorisé en Israël) :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <ul>
                    <li>Touriste <strong>(le décompte s’arrête 45 jours avant la date d’enrôlement prévue) – depuis l’âge de 16 ans :</strong></li>
                    <ul style={{marginBottom: '1rem'}}>
                        <li>Si le candidat n’a pas participé à un programme d’intégration reconnu* il a le droit à 12 mois cumulé en Israël depuis ses 16 ans en prenant en compte qu’il ne doit pas dépasser 120 jours de visite en Israël par année civile et ce, à condition qu’il soit resté à l’étranger plus de 60 jours consécutifs entre les visites en Israël d’une année civile à une autre. ​</li>
                        <li>Si le candidat a participé à un programme d’intégration reconnu*, il a le droit jusqu’à 18 mois cumulé en Israël depuis ses 16 ans. Parmi ces 18 mois, le candidat ne doit pas dépasser 12 mois cumulés en Israël sans cadre reconnu*. De plus, la limite des 120 jours par année civile peut être dérogé une fois uniquement lors de l’année du programme d’intégration. Par exemple un candidat effectuant 6 mois en 2021 et 6 mois en 2022 ne sera pas éligible à Mahal car il aura dépassé deux fois la limite des 120 jours et ce, malgré le fait qu’il n’est pas dépassé 18 mois en cumulé.</li>
                    </ul>
                <span style={{fontWeight: '600'}}>Dans le cas où le candidat n’est pas venu pour plus de 90 jours pendant une année civile ; et sous condition qu’il est resté à l’étranger pendant au moins 60 jours entre une année civile et une autre, alors le décompte de cette année ne sera pas pris en compte.</span>
                </ul>
                <ul>
                    <li>« Ben Méager » <strong>(le décompte s’arrête 45 jours avant la date d’enrôlement prévue) –depuis l’âge de 10 ans :</strong></li>
                    <ul>
                        <li>Le candidat n’ayant participé à aucun programme d’intégration aura le droit jusqu’à 120 jours de visite en Israël par année civile et ce, à condition qu’il soit resté à l’étranger plus de 60 jours consécutifs entre les visites en Israël d’une année civile à une autre.</li>
                        <li>À partir du moment où un candidat n’est pas resté à l’étranger plus de 60 jours consécutifs entre les visites en Israël d’une année civile à une autre OU qu’il a dépassé plus de 120 jours sur une année civile, alors il aura le droit de séjourner en Israël pour une durée de 12 mois à partir de sa date d’arrivée en Israël-sous réserve de justificatif qu’il est dans un programme reconnu (Massa, Yeshiva, études secondaires etc…). Cependant le candidat ne devra pas dépasser sur ces 12 mois plus de 120 jours sans cadre reconnu. Attention, l’armée commence le décompte à partir de la première visite sur l’année civile !</li>
                    </ul>
                    <span style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '15px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>*Un programme reconnu est un programme accepté par le ministère de la défense (Programme Massa, Yeshiva sous Massa, Naalé, école reconnu comme Mikvé Israël). <strong>Les yeshivot ou programme hors-Massa ne sont pas reconnus !</strong></span>
                </ul>
                </Typography>
                <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px' }}>
                            ⚠️ <strong>Attention !</strong> Le fait de quitter le territoire pendant une période de deux mois permet de basculer d’un décompte avec programme a un décompte sans programme. Ainsi un Ben Meager résidant en Israël dans le cadre d’un programme d’intégration entre octobre 2021 et août 2022 sera éligible à Mahal pour la session de décembre 2022 s’il quitte le territoire israélien entre août 2022 à octobre 2022 (durée minimum de 60 jours). De la même façon qu’un Ben Meager ou un touriste qui quitte le territoire pendant plus de deux mois pendant un programme d’intégration, alors lors de sa nouvelle arrivée en Israël, le décompte se fera selon le modèle « sans programme » !

                            </Typography> 
                </div>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '2rem'}}>
                    2. Juif ou Zakai Alyah selon la loi.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    3.  Âges autorisés :
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <ul>
                    <li>À partir de 18 ans.</li>
                    <li>Jusqu’à 23 ans compris pour les garçons et 20 ans compris pour les filles.</li>
                    <li>Jusqu’à 32 ans compris pour les médecins (avec un diplôme reconnu et certifié par le ministère israélien de la Santé).</li>
                </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    4. Les parents en charge du participant au programme n’ont pas fait l’alyah avant les 20 ans du candidat <span style={{fontWeight: '500'}}>(c’est-à-dire qu’ils sont restés jusqu’à 180 jours pour chaque année civile avec 60 jours d’écart entre une année civile à une autre). </span><br />
                    Dans le cas où les parents sont divorcés et celui qui n’a pas la charge du candidat habite en Israël et a fait l’alyah avant les 18 ans du candidat, <span style={{fontWeight: '500'}}>il faudra une lettre du notaire certifiant que le candidat est à la charge du parent 1 et qu’il n’est pas en contact avec le parent résidant en Israël (parent 2).</span>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    5. Le participant s’engage à revenir habiter dans son pays d’origine
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <ul>
                <li>Dans le cas où le candidat touriste effectue son alyah dans les deux années qui suivent son service militaire via Mahal, il devra compléter son service jusqu’à atteindre la durée de <Link to='/service-complet' style={{color: '#1398aa'}}>service complet</Link> en fonction de son âge d’arrivée.</li>
                <li>Dans le cas où le candidat touriste effectue son alyah plus de deux ans après son service militaire via Mahal et que pendant ces deux années il est resté moins de 4 mois en Israël par année civile, alors il sera affecté dans une unité de réservistes en fonction des besoins de Tsahal.</li>
                <li>Les candidats possédant la nationalité israélienne (Ben Meager) n’ont pas le droit de rester sur le territoire israélien plus de 60 jours après la fin de leur service militaire, sous peine de se faire convoquer par Tsahal pour compléter leur service militaire en fonction de leur âge d’arrivée en Israël. De plus, afin d’être exempté d’une convocation de Tsahal pour compléter leur service militaire : ces candidats ne deviendront pas résidents permanents et ne devront pas rester plus de 4 mois en Israël pour les deux années suivant la fin du programme Mahal ou ils feront l’alyah après 26 ans (Tsahal tranchera selon l’âge le plus élevé des deux options).</li>
                </ul>
                </Typography>
                <div style={{borderStyle: 'solid', borderRadius: '20px', backgroundColor: '#EBEAFF', borderColor: '#EBEAFF', marginBottom: '3rem'}}>
                            <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', color: '#2f3036', marginBottom: '1rem', fontWeight: '600', padding: '20px' }}>
                            ❗Les candidats ne respectant pas ces conditions pourront être appelés à compléter leurs services militaires. Le temps de service est calculé en fonction du statut (Ben Meager ou touriste) et surtout leurs âges lors de leurs arrivées en Israël.<br />
                            Jusqu’à aujourd’hui, aucun soldat participant au programme Mahal - qui a décidé de vivre en Israël - n’a complété son service militaire. Nous avons cependant remarqué que d’anciens soldats du programme Mahal ont dû être obligé de servir en tant que réservistes. 
                            </Typography> 
                </div>
                
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700', color: '#2f3036' }}>
                    Les différents parcours
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Il y a trois types de parcours Mahal.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    <ul>
                        <li style={{fontWeight: '700', textDecoration: 'underline'}}>Mahal Nahal</li>
                        <ul>
                            <li><span style={{fontWeight: '600'}}>Parcours Oulpan -</span>  Ce parcours est obligatoire pour tout ce dont le niveau d’hébreu est jugé faible par l’armée lors du <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>. Il s’agit de trois mois de formation d’hébreu dans la base de <Link to='/michve-alon' style={{color: '#1398aa'}}>Michve Alon</Link>. Après cette formation, le soldat continuera son service dans l’unité qu’il aura reçue. Il est important de prendre note que ces trois mois sont pris en compte dans le service militaire. Ce parcours concerne autant les filles que les garçons.</li>
                            <li><span style={{fontWeight: '600'}}>Parcours général/unité combattante -</span> ce parcours s’adresse aux personnes qui ont un niveau d’hébreu élevé lors du <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>. Le participant s’engage directement dans une unité qui aura été décidée après un entretien avec un officier de sélection. Ce parcours concerne autant les filles que les garçons.</li>
                        </ul>
                        <li style={{fontWeight: '700', textDecoration: 'underline', marginTop: '1rem'}}>Mahal Haredi</li>
                            <ul>Ce parcours s’applique pour ceux qui sont religieux et qui veulent être affectés dans une unité combattante religieuse. Il y a trois unités de ce type : le bataillon « Tomer » de la brigade Givati, le bataillon 97 (« Netzah Yehuda ») de la brigade Kfir et dernièrement le bataillon « Hetz » de la brigade des parachutistes (à condition de réussir le test d’accès à cette unité). Dans ce parcours la recrue s’engage directement dans l’une de ces unités pour une durée de 18 mois. Pour ceux qui n’ont pas un profil combattant (au minimum 82), il est possible de servir dans d’autres postes avec ce programme, après un entretien avec un officier de sélection.</ul>
                        <li style={{fontWeight: '700', textDecoration: 'underline', marginTop: '1rem'}}>Mahal Hesder </li>
                            <ul>Ce parcours s’applique à ceux qui font partie d’une yeshiva Hesder et qui veulent s’engager avec celle-ci dans au programme. La recrue doit d’abord être approuvée par le Igud Hesder afin de pouvoir s’inscrire dans ce parcours (parcours pour les garçons seulement). Les inscriptions s’effectuent via la Yeshiva. </ul>
                    </ul>

                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700', color: '#2f3036' }}>
                    Inscription et démarches (hormis Mahal Hesder) et assurances
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                La première démarche consiste à contacter le bureau de Mahal (en anglais ou en hébreu) via leurs mail <span style={{textDecoration: 'underline'}}>prearmyprogram@gmail.com</span>.               
                 </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Pour ceux qui le souhaite, il est possible de recevoir un accompagnement de notre part permettant de fluidifier le processus d'inscription via notre branche <Link style={{color: '#1398aa'}} to='/expert-connection' target="_blank">Expert Connection</Link> (moyennant paiement de 750 NIS TTC).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Notre accompagnement comprend deux entretiens individuels, une préparation aux différents entretiens militaire, une journée de préparation à Mahal et surtout un suivi bienveillant par une équipe passionné pour faciliter votre entrée à l’armée.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Les candidats devront préparer une série de document et envoyer un dossier à l’agence juive via le lien suivant. Nous conseillons de vérifier en parallèle avec Mahal au mail <span style={{textDecoration: 'underline'}}>prearmyprogram@gmail.com</span> que le dossier à bien été reçu par l’Agence juive.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Par la suite et après avoir validé le dossier auprès de l’agence juive, les candidats recevront une convocation pour le premier entretien dans les bureaux de Mahal. Cet entretien se fait en Israël (deux mois avant le début de la session choisie). Après cet entretien, les candidats non israéliens recevront une convocation au <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Après avoir effectué cette journée, les candidats devront participer <strong>OBLIGATOIREMENT</strong> à la mechina (préparation pré-armée). Pendant cette période, les candidats effectueront à l’aide de la mechina un visa A2 Mahal. <strong>Attention, ce visa ne permet pas de travailler avant ou pendant le service militaire.</strong></Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Dans le cas où le candidat à valider la mechina et que tous les entretiens militaires se sont bien déroulés, alors il recevra une autorisation de pouvoir intégrer les rangs de Tsahal.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Il est important de noter que tous les frais pour arriver en Israël ainsi que les frais de transport pour les rendez-vous avec Mahal et la préparation militaires seront à votre charge.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                De plus, les candidats sont assurés pendant la durée de la préparation militaire. Cependant nous conseillons de s’assurer individuellement avant le programme et entre la période de la fin du programme jusqu’à l’enrôlement, car Tsahal peut demander des examens supplémentaires avant ou après la préparation militaire.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                Il existe deux options d'assurance : une assurance classique à 1,95 $/jour et une assurance plus complète (recommandée) d'Harel Clalit à 2,40 $/jour. Pour vous inscrire, vous pouvez remplir <Link to="https://forms.iforms.co.il/Show_form/swfb3l591xjpzy" target="_blank">ce formulaire en ligne</Link>. L'inscription doit être effectuée au plus tard 48 heures avant la date de prise d'effet de l'assurance. Nous recommandons de prendre l’assurance en Israël et non pas une assurance internationale.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                La majorité des soldats de Mahal auront un statut et des avantages en tant que <Link to='/hayal-boded'>soldat seuls</Link> – tant qu’ils ne sont pas en contact avec leurs parents habitant en Israël OU que ceux-ci habitent en Israël. Cependant, ils ne recevront leurs aides de soldats seuls uniquement le 1er du mois qui suit le début du service militaire (s’ils commencent le service à la fin du mois, le salaire sera même le 1 er du mois d’après). Ainsi, nous conseillons les candidats d’avoir un endroit où loger et d’avoir de l’argent de côté pour subvenir à leurs besoins avant de recevoir les aides de Tsahal.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700', color: '#2f3036' }}>
                    Programme pré-armée (Mechina)
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Avant de commencer le service militaire, le participant au programme doit effectuer une préparation militaire qui est obligatoire. Cette préparation dure entre 3 et 4 semaines et se situe généralement un mois avant la date d’entrée à l’armée. Cette préparation militaire est aux frais du ministère de la Défense israélienne et se déroule dans le centre d’intégration de Carmiel.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Il est impossible de s’engager si cette préparation n’a pas été effectuée ! Si certains participants ont des problèmes de disciplines, ils peuvent donc être renvoyés du programme Mahal.  Cette mechina est obligatoire pour tous les candidats du programme Mahal, y compris les participants des programme pré-armée (Gour Arié, Lévia etc…). 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036'}}>
                    Le but du programme est de donner tous les outils nécessaires afin de devenir un bon soldat. De nombreux intervenants, officiers, anciens soldats olim viendront raconter leurs parcours et leurs histoires. De nombreux voyages à travers le pays sont organisés, le tout dans une ambiance militaire.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                    Grâce à leurs connexions dans la ville de Carmiel, l’équipe directive de la mechina facilite les rendez-vous médicaux dans le cas où un candidat doit compléter des tests supplémentaires après le <Link to='/tsav-richon' style={{color: '#1398aa'}}>Tsav Richon</Link>. À la fin du programme, chaque participant sera noté par les différents responsables du programme, ces notes seront envoyées à l’armée et celle-ci peut juger selon les notes d’accepter ou de refuser un participant. Il ne faut donc pas négliger le programme et rester sérieux durant l’intégralité du programme.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700', color: '#2f3036' }}>
                    Liste des documents à préparer
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', fontWeight: '500', marginBottom: '1rem', color: '#2f3036', fontStyle: 'italic'}}>
                    Il faut préparer les documents originaux + 2 photocopies de chaque document, y compris les passeports. 
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    1. Pour valider votre dossier à l’Agence juive (les personnes possédant la nationalités israéliennes sont exemptées de cette étape) – remplir ce formulaire et envoyez les documents.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                a) Passeport francophone du candidat valable jusqu’à 30 mois après la session choisie au minimum (il est préférable que le passeport soit valable trois ans).
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                b) Extrait de casier judiciaire apostillé de chaque pays où il a vécu à partir de l’âge de 14 ans. Ce (ou ces) documents doivent dater de 6 mois avant la date d’incorporation prévue.      
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                c) Acte de naissance apostillé du candidat avec noms des parents et leurs dates de naissances. Il doit daté de 12 mois avant la date d’incorporation prévue.                
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                d) Documents en fonction de la judaïcité du candidat :
                <ul>
                    <li>
                    Candidat étant juif par la mère (selon la halakha) :
                    <ul>
                        <li>Certificat de judaïcité <strong>ORIGINAL</strong> du candidat (ou certificat reconnu par l’agence juive en Israël <strong>UNIQUEMENT</strong>). Il doit être indiqué qu’il est né d'une mère juive, son nom et celui de sa mère tels qu'inscrits sur l'acte de naissance.</li>
                    </ul>
                    </li>
                    <li>
                    Candidat dont le père est juif (Zakai Alyah) : 
                    <ul>
                        <li>Certificat de judaïcité  du père (ou certificat reconnu par l’agence juive en Israël <strong>UNIQUEMENT</strong>). Il doit être indiqué qu’il est né d'une mère juive, son nom et celui de sa mère tels qu'inscrits sur l'acte de naissance.</li>
                        <li>Acte de naissance du père avec les noms de ses parents (si le père est juif de naissance).</li>
                    </ul>
                    </li>
                    <li>
                    Candidat dont le grand-père est juif (Zakai Alyah) :
                    <ul>
                        <li>Certificat de judaïcité  du grand-père (ou certificat reconnu par l’agence juive en Israël <strong>UNIQUEMENT</strong>). Il doit être indiqué qu’il est né d'une mère juive, son nom et celui de sa mère tels qu'inscrits sur l'acte de naissance.</li>
                        <li>L'acte de naissance du parent du candidat dont son parent (donc le grand-père du candidat) est juif, avec les noms et dates de naissance de ses parents.</li>
                        <li>L'acte de naissance du grand-parent juif avec les noms et dates de naissance des parents.</li>
                    </ul>
                    </li>
                    <li>
                    Juif converti depuis minimum 9 mois :
                    <ul>
                        <li>Certificat de conversion émis par l’autorité religieuse qui a procédé à la conversion.</li>
                        <li>Confirmation de participation à la communauté qui couvre au moins neuf mois de participation à partir du jour de la conversion.</li>
                        <li>Une lettre du rabbin qui a procédé à la conversion (uniquement si le candidat s’est converti après ses 12 ans). La lettre doit indiquer la nature et la procédure de conversion, y compris les sujets étudiés et une répartition des heures d’études.</li>
                        <li>Lettre personnelle (uniquement si le candidat s’est converti après ses 12 ans) - le candidat à Mahal doit écrire une lettre personnelle dans laquelle il doit expliquer les raisons de sa conversion ainsi que les détails de la procédure de conversion combien d'heures d'étude, durée totale du processus d'étude, détails sur sa participation à la communauté.</li>
                    </ul>
                    </li>
                </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                    2. Pour Mahal et Tsahal.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <strong>a) Tous les documents indiqués plus haut.</strong>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <strong>b) Formulaire médical + test de vue (à remplir en <Link to="https://1drv.ms/b/s!AgVanePzBHI9gpkZyj46k6pyik1Z9Q?e=Y5PamD" target="_blank" style={{color: '#1398aa'}}>anglais</Link> ou <Link to="https://1drv.ms/b/s!AgVanePzBHI9gaI7Dnhib-U6mR8XTg?e=FoJ8Nq" target='_blank' style={{color: '#1398aa'}}>hébreu</Link>) -</strong> Ce formulaire peut-être rempli par n'importe quel médecin (Israël ou étranger) et a une durée de validité de 3 mois. <Link style={{color: '#1398aa'}} to="https://1drv.ms/b/s!AgVanePzBHI9gpkmzekEAgXQVq1uhg?e=tTPUqh" target="_blank">Voici un document qui explique comment le remplir.</Link>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <strong>c) Justificatifs d’antécédents médicaux (en anglais) –</strong> dans le cas où des problèmes médicaux sont indiqués sur le formulaire médical, Tsahal demandera des justificatifs médicaux (idéalement en anglais ou hébreu) par les médecins spécialistes en fonction du problème (cardiologue pour problème au cœur, etc.). Tant que le dossier médical n’est pas approuvé par Tsahal, le candidat ne pourra pas commencer la préparation militaire (Mechina) et donc son entrée à l’armée sera fortement compromise – peut se fournir plus tard.
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <strong>d) Formulaire d’inscription à Mahal</strong> (en fonction de votre statut de Touriste ou Ben Meager/franco-israélien – <Link to="https://1drv.ms/b/s!AgVanePzBHI9gplATh5DjoLS3_gnKw" target="_blank" style={{color: '#1398aa'}}>document explicatif</Link>)
                    <ul>
                        <li><strong>Touriste :</strong> <Link to="https://1drv.ms/b/s!AgVanePzBHI9gaERUXjVqSb2x0aOhA?e=kbI3TK" target="_blank" style={{color: '#1398aa'}}>hébreu</Link> ou <Link to="https://1drv.ms/b/s!AgVanePzBHI9gaEShUBjSnaO8ANYVQ?e=qjH7vk" target="_blank" style={{color: '#1398aa'}}>anglais</Link>.</li>
                        <li><strong>Ben Meager :</strong>  <Link to="https://1drv.ms/b/s!AgVanePzBHI9gpkiDP8ip31Ypeb7kw?e=egFiQE" target="_blank" style={{color: '#1398aa'}}>hébreu</Link> ou <Link to="https://1drv.ms/b/s!AgVanePzBHI9gpkji177xFIhe0gmlw?e=8cBYHa" target="_blank" style={{color: '#1398aa'}}>anglais</Link>.</li>
                    </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <strong>e) Passeport des parents (document d’un notaire/avocat en anglais si non-contact avec un des parents).</strong>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <strong>f) </strong>Les candidats détenteurs de la nationalité israélienne et ayant des frères/sœurs des mêmes parents inscrits au consulat israélien de leurs pays de résidence seront exemptés de préparer ces documents-là UNIQUEMENT si les deux parents sont israéliens. <span style={{textDecoration:'underline'}}>Dans le cas contraire, ces mêmes candidats seront considérés comme enfants uniques et devront prouver par une des options ci-dessus qu’ils ne sont pas enfants unique. Dans le même temps, chaque personne ne possédant pas la nationalité israélienne et considéré comme enfant unique. Cela empêche la possibilité d’arriver à des unités combattantes. Pour régler ce problème, il faut préparer les documents d’une des options suivantes.</span>
                <ul>
                    <li>
                        Option 1 :
                        <ul>
                            <li>Acte de naissance <strong>apostillé</strong> du candidat.</li>
                            <li>Acte de naissance <strong>apostillé</strong> du frère/sœur..</li>
                        </ul>
                    </li>
                    <li>
                        Option 2 :
                        <ul>
                            <li>Acte de naissance apostillé du candidat.</li>
                            <li>Acte de naissance apostillé du frère/sœur.</li>
                            <li>Attestation des parents en anglais/hébreu certifiant que le candidat n’est pas enfant unique et que plusieurs enfants sont issues de leurs unions.</li>
                            <li>Attestation du candidat en anglais/hébreu devant un avocat/notaire certifiant qu’il n’est pas enfant unique et qu’il possède des frères/sœurs issues des mêmes parents.</li>
                        </ul>
                        </li>
                    <li>
                        Option 3 :
                        <ul>
                        <li>Attestation des parents en anglais/hébreu devant un notaire/avocat certifiant que le candidat n’est pas enfant unique et que plusieurs enfants sont issues de leurs unions. Voici un <Link style={{color: '#1398aa'}} to="https://1drv.ms/b/s!AgVanePzBHI9gaEbnEEr9ChbJHeaYw?e=h33Nhy" target="_blank">document</Link> exemple à faire remplir devant un notaire/avocat.</li>
                        </ul>
                        </li>
                </ul>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <strong>g) Dans le cas où le candidat est vraiment enfant unique (pas de frères/sœur des même parents) :</strong> Il devra donc remplir ce document (lien en <Link style={{color: '#1398aa'}} to="https://1drv.ms/b/s!AgVanePzBHI9gpkePXLhmOEdwQxkng?e=Pgrthb" target="_blank">anglais</Link> ou en <Link style={{color: '#1398aa'}} to="https://1drv.ms/b/s!AgVanePzBHI9gpkgJyIpQD7M5H7isQ?e=AtdhZk" target="_blank">hébreu</Link>). Ce formulaire pour enfant unique sert à servir dans des unités combattantes, il doit être signé par un avocat ainsi que par les parents du futur soldat. Afin de comprendre et savoir comment remplir cette attestation, nous avons mis en place <Link style={{color: '#1398aa'}} to="https://1drv.ms/b/s!AgVanePzBHI9gpkfob2MeGX-6gj3EA?e=1WQmXW" target="_blank">ce document.</Link>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', marginBottom: '2rem', color: '#2f3036'}}>
                <strong>h) Autorisation de participation à un programme d’intégration en hébreu (dans le cas où le candidat a participé à un programme Massa, Yeshiva, Mechina ou autre reconnu par le ministère de la défense israélienne) – </strong> doit-être indiquer sur ce document vos noms complets, vos dates de début et de fin du programme ainsi que vos numéros de passeports si touristes/numéro d’identité israélienne si israélien (numéro à 9 chiffres sur le passeport israélien). Ce document doit être rédigé en anglais ou hébreu. <strong>Nous rappelons que les yeshivot hors Massa ne sont pas reconnues comme programme d’intégration !</strong>
                </Typography>
                <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '22px', fontWeight: '600',color: '#2f3036', marginBottom: '1rem'}}>
                <Link style={{color: '#1398aa'}} to="https://1drv.ms/b/s!AgVanePzBHI9gptbpwFoziVjccVNtQ?e=z6XVIj" target="_blank">Pour mettre de l’ordre dans tout cela, nous avons mis en place un tableau pour que vous vérifiez les documents.</Link>
                </Typography>
              </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
  };
  
  export default Mahal;
  