import React, { useEffect, useState } from 'react';
import Navbar from "../../components/Features/Navbar";
import hr from "./../../images/icons/hr.png";
import { Container, Typography, Button } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS  
import Footer from '../../components/Home/Footer';
import { Link } from 'react-router-dom';
import logoTC from './../../images/Logo Tsahal Conection.png';
import logoAchGadol from './../../images/logos/AchGadol.png';
import Meitav from './../../images/logos/Meitav.png';
import Atuda from './../../images/logos/Atuda.png';
import Mahatz from './../../images/logos/Mahatz.png';
import Aluma from './../../images/logos/Aluma.png';
import Atidim from './../../images/logos/Atidim.png';
import MokedBodedim from './../../images/logos/MokedBodedim.png';
import GarinTzabar from './../../images/logos/GarinTzabar.png';
import CNEF from './../../images/logos/CNEF.png';
import NefeshBeNefesh from './../../images/logos/nefeshBeNefesh.png';
import { Fade } from 'react-awesome-reveal';


const Formulaire = () => {

    const cardData = [
        {   

            title: 'Raphael Madar, Directeur de Tsahal Connection',
            text:  (
                <div>
                    <p><strong>Tel -</strong> 054-9056016</p>
                    <p><strong>Mail -</strong> info@tsahalco.com</p>
                    <p><strong>WhatsApp - </strong> +972-549056016 (Gratuit)</p>
                    <p><strong>Site - </strong><Link to="/" target="_blank" style={{color: 'black'}}>www.tsahalco.com</Link></p>
                </div>
            ),
            image: logoTC
        },
        {
            title: 'Association Ach Gadol pour les soldats seuls',
            text:  (
                <div>
                    <p><strong>Mail -</strong> info@achgadol.org</p>
                    <p><strong>Site - </strong> <Link to="www.achgadol.org" target="_blank" style={{color: 'black'}}>www.achgadol.org</Link></p>
                    <p><strong>Whatsapp -</strong> +972-58-685-3497</p>
                </div>
            ),            
            image: logoAchGadol
        },
        {
            title:'Unité Meitav',
            text:  (
                <div>
                    <p><strong>Tel (depuis Israel)-</strong> 1111</p>
                    <p><strong>WhatsApp (depuis Europe en hébreu OU anglais)- </strong> +33 6 50 76 40 05</p>
                    <p><strong>Mail -</strong> meitav@digital.idf.il</p>
                    <p><strong>Mail (depuis Europe en hébreu OU anglais)- </strong> meitav@paris.mfa.gov.il</p>
                    <p><strong>Site - </strong><Link to="https://www.mitgaisim.idf.il/ " target="_blank" style={{color: 'black'}}>https://www.mitgaisim.idf.il</Link></p>
                </div>
            ),            
            image: Meitav
        },
        {
            title: "Unité Meitav en charge des Olims (Alyah apres 16 ans)",
            text:  (
                <div>
                    <p><strong>Tel -</strong> 03-738-7080</p>
                    <p><strong>Mail -</strong> joinidf@digital.idf.il (possibilité d’écrire en français).</p>
                    <p><strong>WhatsApp - </strong> +972-529458579.</p>
                </div>
            ),   
            image: Meitav
        },
        {
            title: "Branche Atouda",
            text:  (
                <div>
                    <p><strong>Tel -</strong> 03-7387058</p>
                    <p><strong>Mail -</strong> atudaAcademit@gmail.com</p>
                    <p><strong>Site - </strong> <Link style={{color: 'black'}} to="https://www.atuda.org.il" target="_blank">https://www.atuda.org.il</Link></p>
                </div>
            ),   
            image: Atuda
        },
        {
            title: "Branche Haredi pour garçon",
            text:  (
                <div>
                    <p><strong>Tel -</strong> *9779</p>
                    <p><strong>Site - </strong> <Link style={{color: 'black'}} to="https://9779.mod.gov.il/" target="_blank">https://9779.mod.gov.il</Link></p>
                </div>
            ),              
            image: Mahatz
        },
        {
            title: "Aluma (Filles religieuses)",
            text:  (
                <div>
                    <p><strong>Site - </strong> <Link style={{color: 'black'}} to= "www.aluma.org.il" target="_blank">www.aluma.org.il</Link></p>
                    <p><strong>Centre Beit Hel dans Meitav -</strong> 052-4767252</p>
                </div> 
                ),
            image: Aluma
        },
        {
            title: "Atidim",
            text:  (
                <div>
                    <p><strong>Mail -</strong> info@atidim.org</p>
                </div>
            ),   
            image: Atidim
        },
        {
            title: "Centre d'appel pour les soldats seuls (Moked Bodedim)",
            text:  (
                <div>
                    <p><strong>Tel -</strong> 03-7375200</p>
                    <p><strong>Tel (depuis l'étranger) -</strong> +972-3-5691000</p>
                    <p><strong>WhatsApp -</strong> 052-9437725</p>
                </div>
            ),   
            image: MokedBodedim
        },
        {
            title: "Programme Garin Tzabar",
            text:  (
                <div>
                    <p><strong>Site - </strong> <Link style={{color: 'black'}} to= "https://garintzabar.org/ " target="_blank">https://garintzabar.org/</Link></p>
                </div>
            ),   
            image: GarinTzabar
        },
        {
            title: "Association francophones du CNEF",
            text:  (
                <div>
                    <p><strong>Tel -</strong> 02-6222625.</p>
                    <p><strong>Mail -</strong> info@cnef.org. </p>
                    <p><strong>WhatsApp - </strong> +972-584222526</p>
                    <p><strong>Site - </strong><Link to="https://www.cnef.org" target="_blank" style={{color: 'black'}}>https://www.cnef.org</Link></p>
                </div>
            ),  
            image: CNEF
        },
        {
            title: "Programme Nefesh BeNefesh pour les soldats seuls - partie francophone",
            text:  (
                <div>
                    <p><strong>Tel -</strong> 054-707-8090</p>
                    <p><strong>Hotline 24/7 - </strong> 1-8000-71400.</p>
                    <p><strong>Site - </strong><Link to="https://www.cnef.org" target="_blank" style={{color: 'black'}}> https://www.nbn.org.il/</Link></p>
                </div>
            ),
            image: NefeshBeNefesh
        }
    ];

    const [cards, setCards] = useState(new Array(12).fill(false));

    const toggleCard = (index) => {
        const newCards = [...cards];
        newCards[index] = !newCards[index];
        setCards(newCards);
    };

const styles = `
    .hoverUnderline {
        color: #2f3036;
        text-decoration: none;
        transition: text-decoration 0.3s;
    }

    .hoverUnderline:hover {
        text-decoration: underline;
    }
`;

    useEffect(() => {
        document.title = 'Formulaires | Tsahal Connection';
    }, []);

    return (
        <div>
        <style>{styles}</style>
            <Navbar />
            <Fade triggerOnce>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '2rem' }}>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '800', fontSize: '1.2rem', marginBottom: '2rem', color: '#ae39ed', textAlign: 'center' }}>Annexes</h1>
                <h1 style={{ fontFamily: 'Montserrat', fontWeight: '700', fontSize: '48px', textAlign: 'center' }}>Formulaires</h1>
                <img src={hr} alt="HR" width="80" height="50" />
                <Container maxWidth="md" style={{marginTop: '3rem', marginBottom: '3rem'}}>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700' }}>
                        Documents
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '2rem', fontWeight: '600' }}>
                        Tsav Richon
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 Formulaire médical (<Link to="https://1drv.ms/b/s!AgVanePzBHI9gpkZyj46k6pyik1Z9Q" target="_blank">anglais</Link> ou <Link to="https://1drv.ms/b/s!AgVanePzBHI9gaI7Dnhib-U6mR8XTg?e=FoJ8Nq " target="_blank">hébreu</Link>) avec <Link to="https://1drv.ms/b/s!AgVanePzBHI9gpkmzekEAgXQVq1uhg" target="_blank">document explicatif</Link>.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gaJDSnoRlm_1pirCgw?e=psqMfG" target="_blank">Demande pour annuler l’année d’intégration pour les nouveaux immigrants et avancer la date du Tsav Rishon.</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '2rem', fontWeight: '600' }}>
                        Mahal
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 Formulaire d'inscription à Mahal (Touriste/Zakai Alyah : <Link to="https://1drv.ms/b/s!AgVanePzBHI9gaEShUBjSnaO8ANYVQ?e=qjH7vk" target="_blank">anglais</Link> ou <Link to="https://1drv.ms/b/s!AgVanePzBHI9gaERUXjVqSb2x0aOhA?e=kbI3TK" target="_blank">hébreu</Link> ; Ben Meager (israéliens) : <Link to="https://1drv.ms/b/s!AgVanePzBHI9gpkji177xFIhe0gmlw?e=8cBYHa)" target="_blank">anglais</Link> ou <Link to="https://1drv.ms/b/s!AgVanePzBHI9gpkiDP8ip31Ypeb7kw?e=egFiQE" target="_blank">hébreu</Link>) - <Link to="https://1drv.ms/b/s!AgVanePzBHI9gplATh5DjoLS3_gnKw?e=Ia4K4I" target="_blank">Document explicatif</Link>.
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gptbpwFoziVjccVNtQ?e=z6XVIj" target="_blank">Check-List & documents à préparer</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '2rem', fontWeight: '600' }}>
                        Entretiens
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gaJLEvzsgxi91S-Uow?e=j8cs7c" target="_blank">Demande d’entretiens pour la branche des renseignements (Modiin).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gaIR0uQDOL3EUZexmg?e=3r2WTi" target="_blank">Demande pour rejoindre le parcours Atouda Akademit (peut se faire en ligne).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gpkdhmyEolib2SP1pA?e=D05k2q" target="_blank">Demande pour rejoindre le parcours Atouda Olim.</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gaJYJip_oMBBeEH1tQ?e=yY5ws7" target="_blank">Formulaire médical pour les différents tests physiques (Gibbush, Yom Sayerot etc..).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gakHh6nY1AfkgbieUQ?e=wcaWDu" target="_blank">Modèle Sayerot 2024 (garçons).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gakIkrRJ2lHq02hqVw?e=X3XoOu" target="_blank">Modèle Sayerot 2024 (filles).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '2rem', fontWeight: '600' }}>
                        Cas spéciaux
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gpkgJyIpQD7M5H7isQ?e=AtdhZk" target="_blank">Formulaire de volontariat dans les unités combattantes pour enfant unique (en hébreu).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gpkePXLhmOEdwQxkng?e=Pgrthb" target="_blank">Formulaire de volontariat dans les unités combattantes pour enfant unique (en anglais).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gaEbnEEr9ChbJHeaYw?e=h33Nhy" target="_blank">Explication pour remplir le formulaire de volontariat dans les unités combattantes pour enfant unique (en français).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '2rem', fontWeight: '600' }}>
                        Préparations militaires - Mechinot Kdam Tsvaiot
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gaIy1U0dgYkNNmCp2w?e=qFwNmc" target="_blank">Liste des préparations militaires de 6 mois (OFEK).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gppdlJPaUizYtONEMw?e=T43mw0" target="_blank">Liste des préparations militaires annuelle (générales).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gppnLlyYWubRXJlIgQ?e=pBHz7N" target="_blank">Liste des préparations militaires annuelle (religieuses).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '2rem', fontWeight: '600' }}>
                        PGCD de Tsahal Connection:
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gpkzShEJw2JqqZN3yA" target="_blank">Version originale en hébreu.</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gpkx3lWzo18j_9NUWQ" target="_blank">Version traduite en français.</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '2rem', fontWeight: '600' }}>
                        Postes dans Tsahal
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gplRvmg5mAyOccar3w?e=xxWwmI" target="_blank">Liste des postes de Tsahal. </Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '28px', marginBottom: '2rem', fontWeight: '600' }}>
                        Droits et aides​
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '1rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gaJcPAMH1CIRQy4Raw" target="_blank">Brochure des droits des soldats seuls en français (Mars 2023).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '20px', fontWeight: '500', color: '#2f3036', marginBottom: '2rem' }}>
                    🟣 <Link className="hoverUnderline" to="https://1drv.ms/b/s!AgVanePzBHI9gaJh7nTnVukZVHOzvg" target="_blank">Liste d'affaire à préparer et reçue lors de l'enrôlement avec explications sur la carte étoile (Kohavim).</Link>   
                    </Typography>
                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '34px', marginBottom: '2rem', fontWeight: '700' }}>
                        Carnet d'adresses
                    </Typography>
                    <div style={{marginBottom: '4rem'}}>
                        {cardData.map((data, index) => (
                            <div key={index} style={{ border: '1px solid #ccc', borderRadius: '5px', padding: '1rem', marginBottom: '1rem' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                                {data.image && (
                                <img
                                    src={data.image}
                                    alt={`logo for ${data.title}`}
                                    style={{ width: '50px', height: '50px', marginRight: '1rem', borderRadius: '50%' }}
                                />
                            )}
                                    <Typography
                                        variant="body1"
                                        style={{ fontFamily: 'Montserrat', fontSize: '20px', fontWeight: '600', color: '#2f3036', textTransform: 'uppercase', marginTop: '0.5rem'}}
                                    >
                                        {data.title}
                                    </Typography>
                                    <div>
                                        <Button
                                            variant="text"
                                            onClick={() => toggleCard(index)}
                                            style={{ fontFamily: 'Montserrat', fontSize: '20px', fontWeight: '500', color: '#2f3036', outline: 'none' }}
                                        >
                                            {cards[index] ? '-' : '+'}
                                        </Button>
                                    </div>
                                </div>
                                {cards[index] && (
                                    <Typography variant="body1" style={{ fontFamily: 'Montserrat', lineHeight: '1.8', fontSize: '18px', marginTop: '1rem' }}>
                                        {data.text}
                                    </Typography>
                                )}
                            </div>
                        ))}
                        </div>                    
                </Container>
            </div>
            <Footer />
            </Fade>
        </div>
    );
};

export default Formulaire;
